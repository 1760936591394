<template>
        <div class="p-8 bg-containerbg backdrop-blur-3xl rounded-lg shadow-lg" v-if="content" v-html="content"></div>
</template>

<script lang="ts">

export default defineNuxtComponent({
    name: 'simpleContentElementHtml4',
    props: {
        content:{
            type: String,
        }
    },
    setup(props) {
        const url = useRuntimeConfig().public.API_BASE;

        if(props.content == undefined){
            // Throw error
        }else{

            const content = props.content.replaceAll('${sitectx.basedir}', url);
            return {content, url}
        }
    }
})
</script>